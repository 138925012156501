.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html{
  scroll-behavior: smooth;
}


@font-face {
  font-family: 'MabryPro';
  src: url('/src/Assets/fonts/MabryPro-Regular.ttf') format('woff');
 
}
@font-face {
  font-family: 'Aldrich';
  src: url('/src/Assets/fonts/Aldrich-Regular.ttf') format('woff');
 
}

.custom-button {
  background: #D9FE72 !important;
  color: #000 !important;
  transition: 0.3s all !important;
  text-transform: capitalize !important;
  width: 127px !important;
  font-weight: 600 !important;
  border-radius: 30px !important;
  font-family: "MabryPro" !important;
  font-size: 16px !important;
}

.custom-button:hover {
  background: #000 !important;
  color: #D9FE72 !important;
}

.bin1 {
  border: 1px solid #BBBBBB !important;
  border-radius: 5px;
  width: 100%;
  background: transparent;
  color: #BBBBBB;
  height: auto !important;
}
.bin1 input[type=number] {
  -moz-appearance: textfield;
}
.bin1 input[type=number]::-webkit-outer-spin-button,
.bin1 input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bin1:focus-visible {
  outline: 1px solid #BBBBBB !important;
  border: 1px solid #BBBBBB !important;
}
.bin1:hover {
  border: 1px solid #BBBBBB !important;
}
.bin1:focus {
  box-shadow: none !important;
  border: none !important;
  border-radius: 5px !important;
}
.bin1 input {
  padding: 5px 15px;
  font-size: 16px;

  color: #999999 !important;
}
.bin1 input::placeholder {

  color: #000 !important;
}
.bin1 textarea {
  font-size: 15px;
  color: #fff !important;
  padding: 10px !important;
}